import styled from 'styled-components';

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;

  > span {
    user-select: none;
    font-size: 14px;
  }
`;
