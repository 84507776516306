import styled from 'styled-components';
import {
  Container as MuiContainer,
  ContainerProps as MuiContainerProps,
} from '@material-ui/core';
import { margin, MarginProps, padding, PaddingProps } from 'helpers';

export type ContainerProps = MuiContainerProps & PaddingProps & MarginProps;

/**
 * Wrapper component for content. By default it's width es equal to breakpoints values.
 */
export const Container = styled(MuiContainer).attrs<ContainerProps>(
  ({ maxWidth = false, disableGutters = false, fixed = true }) => ({
    maxWidth,
    disableGutters,
    fixed,
  }),
)<ContainerProps>`
  ${padding};
  ${margin};
`;

export const TintedContainer = styled(Container).attrs({ maxWidth: false })`
  background-color: ${({ theme }) => theme.palette.background.light};
`;
