import React, { useCallback } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@material-ui/lab';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import styled from 'styled-components';
import { Input, InputProps } from '../Input';
import { SelectPaper } from '../Select';

export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<
  MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput'
> & {
  /**
   * This props will be passed to renderInput
   * */
  inputProps?: InputProps;
  renderInput?: MuiAutocompleteProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >['renderInput'];
};

export function Autocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  inputProps,
  options,
  renderInput,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  const defaultRenderInput = useCallback(
    params => (
      <Input
        ref={params.InputProps.ref}
        {...params}
        {...inputProps}
        inputProps={{ ...params.inputProps, ...inputProps?.inputProps }}
      />
    ),
    [],
  );

  return (
    <BaseAutocomplete
      options={options}
      renderInput={renderInput || defaultRenderInput}
      popupIcon={<ExpandMoreRoundedIcon />}
      PaperComponent={SelectPaper}
      {...props}
    />
  );
}

export const BaseAutocomplete = styled(MuiAutocomplete)`
  .MuiAutocomplete-inputRoot {
    padding-top: ${({ multiple }) => (multiple ? '9px' : 0)};
    padding-bottom: ${({ multiple }) => (multiple ? '9px' : 0)};
    padding-left: ${({ multiple }) => (multiple ? '' : 0)};
    padding-left: ${({ multiple }) => (multiple ? '' : 0)};
    min-height: ${({ multiple }) => (multiple ? '60px' : 'auto')};
    box-sizing: border-box;
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon {
    padding-right: calc(18px + 24px + 8px);
  }

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input.MuiOutlinedInput-input {
    height: ${({ multiple }) => (multiple ? 'auto' : '60px')};
    padding: ${({ multiple }) => (multiple ? '' : '0 0 0 18px')};
  }
`;
