export type Impact = 1 | 2 | 3 | 4 | 5;

export const HORMONAL_RES: Record<Impact, string> = {
  1: 'Very unlikely',
  2: 'Unlikely',
  3: 'Appears',
  4: 'Likely',
  5: 'Highly likely',
};

export const CLINICAL_RES: Record<Impact, string> = {
  1: 'Very low',
  2: 'Low',
  3: 'Medium',
  4: 'High',
  5: 'Very high',
};

export const VALUES: Record<Impact, number> = {
  1: 0,
  2: 25,
  3: 50,
  4: 75,
  5: 100,
};
