import styled from 'styled-components';
import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
} from '@material-ui/core';
import { margin, MarginProps, padding, PaddingProps } from 'helpers';

export type DividerProps = MuiDividerProps & PaddingProps & MarginProps;

export const Divider = styled(MuiDivider)<DividerProps>`
  ${margin};
  ${padding};
`;
