import styled from 'styled-components';

export const LayoutWrapper = styled.div<{ $isBackdropOpen: boolean }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: ${({ $isBackdropOpen }) => ($isBackdropOpen ? '100vh' : 'auto')};
  overflow: ${({ $isBackdropOpen }) => ($isBackdropOpen ? 'hidden' : '')};
`;

export const Main = styled.main`
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
