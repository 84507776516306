import React from 'react';
import { ThemeProvider } from 'styled-components';
import {
  ThemeProvider as MuiProvider,
  CssBaseline,
  StylesProvider,
} from '@material-ui/core';
import theme from './index';

const Provider: React.FC = ({ children }) => (
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      <MuiProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiProvider>
    </ThemeProvider>
  </StylesProvider>
);

export default Provider;
