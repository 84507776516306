import styled from 'styled-components';

type ElementProps = {
  isActive: boolean;
};

export const StepsWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const Step = styled.div<ElementProps>`
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.stepper.active : theme.palette.stepper.default};

  :last-of-type {
    margin-right: 0;
  }
`;

export const Dash = styled(Step)`
  width: 78px;
  height: 5px;
  margin-right: 24px;

  @media ${({ theme }) => theme.media.tablet} {
    width: 50px;
    margin-right: 12px;
  }
`;

export const Dot = styled(Step)`
  width: 10px;
  height: 10px;
  margin-right: 12px;
  border-radius: 50%;
`;
