import { breakpoints } from './breakpoints';
import { Typography } from '@material-ui/core/styles/createTypography';

export const fontSizes: Partial<Typography> = {
  h1: {
    fontWeight: 600,
    fontSize: '1.90625rem',
    lineHeight: 1.244,
    [`@media ${breakpoints.mobile}`]: {
      fontSize: '2.3311rem',
      lineHeight: 1.244,
    },
    [`@media ${breakpoints.tablet}`]: {
      fontSize: '2.5859rem',
      lineHeight: 1.244,
    },
    [`@media ${breakpoints.laptop}`]: {
      fontSize: '2.8125rem',
      lineHeight: 1.244,
    },
  },
  h2: {
    fontWeight: 600,
    fontSize: '1.5625rem',
    lineHeight: 1.529,
    [`@media ${breakpoints.mobile}`]: {
      fontSize: '1.8262rem',
      lineHeight: 1.529,
    },
    [`@media ${breakpoints.tablet}`]: {
      fontSize: '1.9844rem',
      lineHeight: 1.529,
    },
    [`@media ${breakpoints.laptop}`]: {
      fontSize: '2.125rem',
      lineHeight: 1.529,
    },
  },
  h3: {
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: 1.416,
    [`@media ${breakpoints.mobile}`]: {
      fontSize: '1.3672rem',
      lineHeight: 1.416,
    },
    [`@media ${breakpoints.tablet}`]: {
      fontSize: '1.4375rem',
      lineHeight: 1.416,
    },
    [`@media ${breakpoints.laptop}`]: {
      fontSize: '1.5rem',
      lineHeight: 1.416,
    },
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: 1.6,
    [`@media ${breakpoints.mobile}`]: {
      fontSize: '1.1836rem',
      lineHeight: 1.6,
    },
    [`@media ${breakpoints.tablet}`]: {
      fontSize: '1.2188rem',
      lineHeight: 1.6,
    },
    [`@media ${breakpoints.laptop}`]: {
      fontSize: '1.25rem',
      lineHeight: 1.6,
    },
  },
  h5: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: 0.2,
    [`@media ${breakpoints.mobile}`]: {
      fontSize: '1.125rem',
      lineHeight: 1.33,
    },
  },
  h6: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: 1.428,
  },
  body1: {
    fontSize: '1.125rem',
    lineHeight: 1.33,
  },
  body2: {
    fontSize: '0.75rem',
    lineHeight: 1.33,
    fontWeight: 300,
    [`@media ${breakpoints.mobile}`]: {
      fontSize: '0.875rem',
      lineHeight: 1.143,
    },
  },
  subtitle1: {
    fontSize: '1.125rem',
    lineHeight: 1,
    letterSpacing: 0.15,
    fontWeight: 600,
    [`@media ${breakpoints.mobile}`]: {
      fontSize: '1.1836rem',
      lineHeight: 1,
    },
    [`@media ${breakpoints.tablet}`]: {
      fontSize: '1.2188rem',
      lineHeight: 1,
    },
    [`@media ${breakpoints.laptop}`]: {
      fontSize: '1.25rem',
      lineHeight: 1,
    },
  },
  subtitle2: {
    fontSize: '0.563rem',
    lineHeight: 1.33,
    letterSpacing: 0.15,
    fontWeight: 500,
    [`@media ${breakpoints.mobile}`]: {
      fontSize: '0.75rem',
      lineHeight: 1.5,
    },
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: 1.5,
  },
  overline: {
    fontSize: '0.625rem',
    lineHeight: 1.5,
    letterSpacing: 0.3,
    [`@media ${breakpoints.mobile}`]: {
      fontSize: '0.75rem',
      lineHeight: 1.5,
    },
  },
  button: {
    fontSize: '0.875rem',
    lineHeight: 1.5,
    letterSpacing: '0.1rem',
    fontWeight: 400,
    [`@media ${breakpoints.mobile}`]: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
  // custom
  body3: {
    fontSize: '0.5rem',
    lineHeight: 1.5,
    mobile: {
      fontSize: '0.625rem',
      lineHeight: 1.5,
    },
  },
};

export const fontFamily = [
  'Poppins',
  'Roboto',
  'Helvetica',
  'Arial',
  'sans-serif',
].join(',');
