import styled from 'styled-components';
import { H4 } from '../Elements/HEADING';
import { BODY2, CAPTION } from '../Elements/P';

export const Block = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  ${H4} {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

export const Scale = styled.div<{ $clickable: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  ${CAPTION} {
    margin-bottom: 4px;

    text-decoration: underline;
    cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'inherit')};
  }
`;

export const Snippet = styled(BODY2)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const Icon = styled.div`
  svg {
    font-size: 24px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
`;
