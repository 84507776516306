import React, { ReactNode, useMemo } from 'react';
import { CLINICAL_RES, HORMONAL_RES, Impact, VALUES } from './scales';
import { Block, Header, Icon, Scale, Snippet } from './styles';
import { H4 } from '../Elements/HEADING';
import { CAPTION } from '../Elements/P';
import { LinearProgress } from '../Elements/LinearProgress';

export type SymptomBlockProps = {
  /**
   * Symptom icon
   * */
  icon: ReactNode;
  /**
   * Symptom name
   * */
  name: string;
  /**
   * Symptom hormonal impact
   * */
  hormonal: Impact;
  /**
   * Symptom clinical impact
   * */
  clinical: Impact;
  /**
   * Custom title for hormonal scale
   * */
  hormonalTitle?: string;
  /**
   * Custom title for clinical scale
   * */
  clinicalTitle?: string;
  /**
   * Symptom snippet generic
   * */
  snippet: string;
  onHormonalClick?: () => void;
  onClinicalClick?: () => void;
  className?: string;
};

export const SymptomBlock: React.FC<SymptomBlockProps> = ({
  icon,
  name,
  hormonal,
  clinical,
  snippet,
  hormonalTitle,
  clinicalTitle,
  className,
  onHormonalClick,
  onClinicalClick,
}) => {
  const scales = useMemo(
    () => [
      {
        id: 'hormonal',
        name:
          hormonalTitle || `${HORMONAL_RES[hormonal]} to be linked to hormones`,
        value: VALUES[hormonal],
        onClick: onHormonalClick,
      },
      {
        id: 'clinical',
        name: clinicalTitle || `${CLINICAL_RES[clinical]} clinical impact`,
        value: VALUES[clinical],
        onClick: onClinicalClick,
      },
    ],
    [
      hormonal,
      clinical,
      onHormonalClick,
      onClinicalClick,
      clinicalTitle,
      hormonalTitle,
    ],
  );

  return (
    <Block className={className}>
      <Header>
        <Icon>{icon}</Icon>
        <H4>{name}</H4>
      </Header>
      {scales.map(({ id, name, value, onClick }) => (
        <Scale key={id} $clickable={!!onClick}>
          <CAPTION display="block" onClick={onClick}>
            {name}
          </CAPTION>
          <LinearProgress
            value={value}
            avoidZero
            intensity={[50, 100, 200, 300, 400, 500]}
          />
        </Scale>
      ))}
      <Snippet color="textSecondary">{snippet}</Snippet>
    </Block>
  );
};
