import React, { useCallback, useState, useEffect } from 'react';
import {
  BenefitList,
  BenefitListHeaderContainer,
  BenefitListContainer,
  RecommendationTableConatainer,
  BenefitTitle,
  BenefitListContent,
} from './styles';
import {
  RecommendationCard,
  RecommendationCardProps,
} from './RecommendationCard';
import { Collapse, useMediaQuery, useTheme } from '@material-ui/core';
import { BaseLink as Link } from 'components/Elements/Link';

export type RecommendationTableProps = {
  recommendations: Omit<
    RecommendationCardProps,
    'benefitStateArray' | 'setBenefitStateArrayCallback'
  >[];
  prioritizeLinkClickCallback: () => void;
};

export type BenefitStateProps = {
  name: string;
  isOpen: boolean;
  height: number;
};

export const RecommendationTable: React.FC<RecommendationTableProps> = ({
  recommendations,
  prioritizeLinkClickCallback,
}) => {
  const [benefitStateArray, setBenefitStateArray] = useState<
    BenefitStateProps[] | undefined
  >([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (recommendations[0].benefits) {
      const newBenefitStateArray:
        | BenefitStateProps[]
        | undefined = recommendations[0].benefits.map(benefit => {
        const lengths = recommendations.map(
          rec =>
            rec.benefits?.find(element => element.name === benefit.name)
              ?.caption?.length || 140,
        );
        const maxLength = Math.max(...lengths);
        const height = (maxLength / (isMobile ? 40 : 60)) * 26 + 20;
        return {
          name: benefit.name,
          isOpen: false,
          height: height,
        };
      });
      setBenefitStateArray(newBenefitStateArray);
    }
  }, [isMobile]);

  const setBenefitStateArrayCallback = useCallback(updatedBenefitStateArray => {
    setBenefitStateArray(updatedBenefitStateArray);
  }, []);

  return (
    <RecommendationTableConatainer>
      {!isMobile ? (
        <BenefitList>
          <BenefitListContent>
            <BenefitListHeaderContainer>
              <Link
                variant="body2"
                display="block"
                paragraph
                onClick={prioritizeLinkClickCallback}
              >
                How do we prioritize?
              </Link>
            </BenefitListHeaderContainer>
            {benefitStateArray?.map(benefit => (
              <div key={benefit.name}>
                <BenefitListContainer>
                  {
                    recommendations[0].benefits?.find(
                      element => element.name === benefit.name,
                    )?.icon
                  }
                  <BenefitTitle>{benefit.name}</BenefitTitle>
                </BenefitListContainer>
                <Collapse
                  in={benefit.isOpen}
                  unmountOnExit
                  collapsedHeight={benefit.height}
                  timeout={100}
                />
              </div>
            ))}
          </BenefitListContent>
        </BenefitList>
      ) : null}
      {recommendations.map(recommendation => (
        <RecommendationCard
          key={recommendation.name}
          name={recommendation.name}
          generics={recommendation.generics}
          status={recommendation.status}
          type={recommendation.type}
          benefits={recommendation.benefits}
          benefitStateArray={benefitStateArray}
          setBenefitStateArrayCallback={setBenefitStateArrayCallback}
          onLearnMoreClickCallback={recommendation.onLearnMoreClickCallback}
        />
      ))}
    </RecommendationTableConatainer>
  );
};
