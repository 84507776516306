import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { margin, padding } from 'helpers';
import { TypographyProps } from '../P';

const H = styled(Typography)`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : '')};

  ${margin};
  ${padding};
`;

export const H1 = styled(H).attrs<TypographyProps>({
  variant: 'h1',
})<TypographyProps>``;

export const H2 = styled(H).attrs<TypographyProps>({
  variant: 'h2',
})<TypographyProps>``;

export const H3 = styled(H).attrs<TypographyProps>({
  variant: 'h3',
})<TypographyProps>``;

export const H4 = styled(H).attrs<TypographyProps>({
  variant: 'h4',
})<TypographyProps>``;

export const H5 = styled(H).attrs<TypographyProps>({
  variant: 'h5',
})<TypographyProps>``;

export const H6 = styled(H).attrs<TypographyProps>({
  variant: 'h6',
})<TypographyProps>``;
