import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const QuestionMark: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M9.47802 6.032C8.93802 6.8 8.01402 7.316 7.52202 8.192C7.13802 8.888 7.22202 9.692 7.21002 10.184H9.03402C9.03402 9.248 9.09402 9.056 9.25002 8.768C9.64602 8.048 10.366 7.712 11.122 6.596C11.806 5.588 11.554 4.232 11.11 3.512C10.594 2.672 9.57402 2 8.09802 2C6.33402 2 5.12202 3.152 4.66602 4.496L6.31002 5.18C6.52602 4.508 7.05402 3.704 8.11002 3.704C9.73002 3.704 10.054 5.216 9.47802 6.032Z" />
      <path d="M8.11002 11.492C7.41402 11.492 6.85002 12.032 6.85002 12.74C6.85002 13.436 7.41402 14 8.11002 14C8.83002 14 9.35802 13.436 9.35802 12.74C9.35802 12.032 8.81802 11.492 8.11002 11.492Z" />
    </SvgIcon>
  );
};
