import { Tab as MuiTab } from '@material-ui/core';
import styled from 'styled-components';

export const Tab = styled(MuiTab)`
  margin-right: 32px;
  padding: 0 0 8px;
  min-width: auto;

  &.MuiTab-textColorSecondary:not(.Mui-selected) {
    color: ${({ theme }) => theme.palette.common.purple[100]};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-bottom: 16px;
    margin-right: 18px;
    font-size: 1rem;
  }
`;
