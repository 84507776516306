import styled from 'styled-components';
import {
  Accordion as MuiAccordion,
  AccordionProps as MuiAccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps as MuiAccordionSummaryProps,
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps as MuiAccordionDetailsProps,
  AccordionActions as MuiAccordionActions,
  AccordionActionsProps as MuiAccordionActionsProps,
} from '@material-ui/core';
import { margin, MarginProps, padding, PaddingProps } from 'helpers';
import React from 'react';
import { ExpandMore } from '@material-ui/icons';

export type AccordionProps = MuiAccordionProps &
  PaddingProps &
  MarginProps & {
    /**
     * By disabling paper you remove styles associated with it: background, elevation, variant
     **/
    $disablePaper?: boolean;
  };

/**
 * An `Accordion` is a lightweight container that may either stand alone or be connected to a larger surface, such as a card.<br/>
 * `AccordionSummary` is a part of accordion that is visible when it is collapsed.<br/>
 * `AccordionDetails` ia a collapsable part.<br/>
 * `AccordionActions` is a bottom part of accordion details usually used for CTAs.
 */
export const Accordion = styled(MuiAccordion).attrs<AccordionProps>(
  ({ $disablePaper = true, elevation, variant }) => ({
    $disablePaper,
    elevation: $disablePaper ? 0 : elevation,
    variant: $disablePaper ? 'elevation' : variant,
  }),
)<AccordionProps>`
  background-color: ${({ theme, $disablePaper }) =>
    $disablePaper ? 'transparent' : theme.palette.common.white};

  &,
  &:first-child {
    border-top: ${({ variant }) =>
      variant === 'outlined' ? '1px solid #EEEEEE' : 'none'};
  }

  padding-left: 0;
  padding-right: 0;

  ${padding};
  ${margin};

  &&.Mui-expanded {
    ${padding};
    ${margin};
  }
`;

export type AccordionSummaryProps = MuiAccordionSummaryProps & PaddingProps;

export const AccordionSummary = styled(
  MuiAccordionSummary,
).attrs<AccordionSummaryProps>(({ expandIcon, IconButtonProps }) => ({
  expandIcon: expandIcon || <ExpandMore />,
  IconButtonProps: IconButtonProps || {
    disableRipple: true,
  },
}))<AccordionSummaryProps>`
  .MuiIconButton-root {
    color: ${({ theme }) => theme.palette.common.black};
  }

  padding-top: 8px;
  padding-bottom: 8px;

  &,
  &&.Mui-expanded {
    min-height: auto;
  }

  ${padding};
`;

export type AccordionDetailsProps = MuiAccordionDetailsProps & PaddingProps;

export const AccordionDetails = styled(
  MuiAccordionDetails,
)<AccordionDetailsProps>`
  padding-top: 8px;
  padding-bottom: 8px;

  ${padding};
`;

export type AccordionActionsProps = MuiAccordionActionsProps & PaddingProps;

export const AccordionActions = styled(
  MuiAccordionActions,
)<AccordionActionsProps>`
  padding-top: 8px;
  padding-bottom: 8px;

  ${padding};
`;
