import styled from 'styled-components';
import { Card as MUICard } from '@material-ui/core';

export const Card = styled(MUICard)`
  border-radius: 21px;
  box-shadow: ${({ theme }) => theme.card.shadow.primary};
`;

export const SimpleCard = styled(MUICard)`
  box-shadow: ${({ theme }) => theme.card.shadow.secondary};
`;
