import React from 'react';
import styled from 'styled-components';
import {
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { margin, MarginProps } from 'helpers';

export type ChipProps = MuiChipProps & {
  /**
   * If `true` has small border-radius. If `false` has default rounded corners.
   * @default true
   * */
  $square?: boolean;
} & MarginProps;

const BaseChip = styled(MuiChip).attrs<ChipProps>(({ $square = true }) => ({
  $square,
}))<ChipProps>`
  border-radius: ${({ $square, theme }) =>
    $square ? `${theme.borderRadius.small}px` : undefined};
  background-color: #f3f4f4;

  && {
    height: fit-content;
  }

  .MuiChip-label {
    padding: 8px 16px;
  }

  .MuiChip-label.MuiChip-labelSmall {
    padding: 4px 12px;
  }

  ${margin};
`;

/**
 * Chips represent complex entities in small blocks, such as a contact.
 * */
export const Chip: React.FC<ChipProps> = ({ size, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <BaseChip size={size || (isMobile ? 'small' : 'medium')} {...props} />;
};
