import React from 'react';
import { Intensity, ColourName } from '@material-ui/core/styles/createPalette';
import {
  ProgressWrapper,
  ProgressBar,
  ProgressTrack,
  Indicator,
} from './styles';

export interface LinearProgressProps {
  /**
   * If `true`, has rounded corners.
   * @default true
   */
  rounded?: boolean;
  /**
   * The value of the progress indicator.
   * Value between 0 and 100.
   * @default 100
   */
  value: number;
  /**
   * Defines should there be an indicator or not
   * @default true
   */
  indicator?: boolean;
  /**
   * Redefines the colour of the progress bar and indicator
   * @default purple
   */
  colour?: ColourName;
  /**
   * Indicates the intensity of the colour for the progress bar.
   * Single number is used to create solid bar.
   * Multiple intensity is used to create gradient bar.
   * NOTE: indicator colour will be of the last intensity in array.
   * @default 900.
   */
  intensity?: Intensity | Intensity[];
  /**
   * Zero value will be displayed as non-zero little value.
   * @default true
   */
  avoidZero?: boolean;
}

/**
 * is a Linear progress indicator
 * */
export const LinearProgress: React.FC<LinearProgressProps> = ({
  value = 100,
  rounded = true,
  indicator = true,
  colour = 'purple',
  intensity = 900,
  avoidZero = true,
}) => {
  return (
    <ProgressWrapper $indicator={indicator}>
      <ProgressTrack $rounded={rounded}>
        <ProgressBar
          $value={value}
          $colour={colour}
          $intensity={intensity}
          $avoidZero={avoidZero}
          $indicator={indicator}
        />
      </ProgressTrack>
      {indicator && (
        <Indicator
          $value={value}
          $colour={colour}
          $intensity={intensity}
          $avoidZero={avoidZero}
        />
      )}
    </ProgressWrapper>
  );
};
