import styled from 'styled-components';

type IconProps = {
  size: number;
};

const IconSvg = styled.svg.attrs(({ size }: IconProps) => ({
  viewBox: '0 0 1024 1024',
  width: `${size}px`,
  height: `${size}px`,
}))<IconProps>`
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: calc(50% - ${({ size }) => size / 2}px);
  top: calc(50% - ${({ size }) => size / 2}px);
  path {
    fill: ${props => props.color};
    transition: all 0.3s;
  }
`;

const IconWrapper = styled.div<IconProps>`
  position: relative;
  width: ${({ size }) => `${size}px`};
  min-width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin: 0;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : '')};
`;

export { IconSvg, IconWrapper };
