import React, { ReactElement, useCallback } from 'react';
import SwappableViews from 'react-swipeable-views';
import { autoPlay, virtualize } from 'react-swipeable-views-utils';
import { Box } from '@material-ui/core';

const VirtualizeSwappableViews = virtualize(autoPlay(SwappableViews));

interface ScrollableContainerProps {
  components: ReactElement[];
  onStepChange?: (step: number) => void;
}

export const ScrollableContainer: React.FC<ScrollableContainerProps> = ({
  components,
  onStepChange,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = useCallback(
    (step: number) => {
      setActiveStep(step);

      if (onStepChange) {
        const dataIndex = Math.abs(
          step - components.length * Math.floor(step / components.length),
        );
        onStepChange(dataIndex);
      }
    },
    [components],
  );

  const slideRenderer = useCallback(
    ({ key, index }) => {
      const dataIndex = Math.abs(
        index - components.length * Math.floor(index / components.length),
      );
      return React.cloneElement(components[dataIndex], { key });
    },
    [components],
  );

  return (
    <Box width="100%">
      <VirtualizeSwappableViews
        autoplay={components.length > 1}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        slideRenderer={slideRenderer}
      />
    </Box>
  );
};
