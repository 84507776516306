import styled from 'styled-components';
import { CardContent as Content, Container } from '@material-ui/core';
import { Chip, Card, Button, BODY2, CAPTION } from 'components/Elements';
import IconButton from '@material-ui/core/IconButton';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import { QuestionMarkIcon } from 'assets/icons';

// Table components
export const RecommendationTableConatainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 20px 20px 10px 0;
`;

export const BenefitList = styled(Card)`
  box-shadow: none;
  width: 240px;
  background-color: ${({ theme }) => theme.palette.common.white};
  flex-shrink: 0;
`;

export const BenefitListHeaderContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 0;
  height: 120px;
`;

export const BenefitListContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 18px 0 0;
  height: 60px;
  border-top: 1px solid rgba(0, 0, 0, 0.14);
`;

export const CardContent = styled(Content)`
  padding: 24px;
`;

export const BenefitListContent = styled(Content)`
  padding: 24px 24px 24px 0;
`;

export const BenefitTitle = styled(BODY2)`
  padding: 4px 0 0 5px;
  color: ${({ theme }) => theme.palette.common.grey[600]};
`;

// Recommendation card components
export const RecommendationCard = styled(Card)<{
  $status: 'best' | 'current' | 'currentUnrecommended';
  $isMobile: boolean;
}>`
  width: ${({ $isMobile }) => ($isMobile ? '320px' : '400px')};
  background-color: ${({ theme, $status }) => {
    switch ($status) {
      case 'best':
        return theme.palette.common.green[50];
      case 'current':
        return theme.palette.common.grey[100];
      case 'currentUnrecommended':
        return theme.palette.common.red[50];
      default:
        return theme.palette.common.grey[100];
    }
  }};
  margin-right: 15px;
  flex-shrink: 0;
`;

export const StyledChip = styled(Chip)<{
  $status: 'best' | 'current' | 'currentUnrecommended';
}>`
  border-radius: 15px;
  margin: 4px 4px 0 0;
  background-color: ${({ theme, $status }) => {
    switch ($status) {
      case 'best':
        return theme.palette.common.green[100];
      case 'current':
        return theme.palette.common.grey[300];
      case 'currentUnrecommended':
        return theme.palette.common.red[100];
      default:
        return theme.palette.common.green[100];
    }
  }};
`;

export const BenefitDetailContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 60px;
  margin: 0;
  padding: 18px 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.14);
`;

export const GenericsCaptionContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
`;

export const GenericsCaption = styled(CAPTION)<{
  $isMobile: boolean;
}>`
  width: ${({ $isMobile }) => ($isMobile ? '220px' : '320px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BenefitCaptionContainer = styled(Container)`
  height: 50px;
  margin-bottom: 20px;
`;

export const ChipContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px 0 0;
`;

export const HeaderContainer = styled(Container)`
  padding: 0;
  height: 120px;
`;

export const StyledButton = styled(Button)<{
  $status: 'best' | 'current' | 'currentUnrecommended';
}>`
  margin: 30px 0 0;
  color: black;
  background-color: ${({ theme, $status }) => {
    switch ($status) {
      case 'best':
        return theme.palette.common.green[100];
      case 'current':
        return theme.palette.common.grey[300];
      case 'currentUnrecommended':
        return theme.palette.common.red[100];
      default:
        return theme.palette.common.green[100];
    }
  }};
  :hover {
    background-color: ${({ theme, $status }) => {
      switch ($status) {
        case 'best':
          return theme.palette.common.green[100];
        case 'current':
          return theme.palette.common.grey[300];
        case 'currentUnrecommended':
          return theme.palette.common.red[100];
        default:
          return theme.palette.common.green[100];
      }
    }};
  }
`;

export const BenefitCaption = styled(CAPTION)`
  color: ${({ theme }) => theme.palette.common.grey[700]};
`;

export const ExpandRowIconButton = styled(IconButton)`
  margin-left: auto;
  padding-bottom: 20px;
`;

// Styled Icons
export const CheckCircleIcon = styled(CheckCircle)`
  fill: ${({ theme }) => theme.palette.common.green[500]};
`;

export const CrossCircleIcon = styled(CancelRoundedIcon)`
  fill: ${({ theme }) => theme.palette.common.red[500]};
`;

export const HelpCircleIcon = styled(HelpRoundedIcon)`
  fill: ${({ theme }) => theme.palette.common.yellow[500]};
`;

export const SmallQuestionMarkIcon = styled(QuestionMarkIcon)`
  font-size: 1.2rem;
  padding-top: 4px;
`;
