import styled from 'styled-components';
import {
  AppBar,
  Paper,
  MenuList as MuiMenuList,
  MenuItem as MuiMenuItem,
} from '@material-ui/core';
import { Container } from 'components/Elements';

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 42px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-right: 0;
  }
`;

export const MainContainer = styled(Container)`
  height: 56px;
  display: flex;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 80px;
  }
`;

export const StyledAppBar = styled(AppBar)<{
  $isScrolled?: boolean;
}>`
  &.MuiAppBar-colorDefault {
    background-color: ${({ theme }) => theme.palette.background.main};
    transition: border-bottom-color 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    .MuiLink-root {
      color: ${({ theme }) => theme.palette.text.secondary};
    }
  }
`;

export const NavPaper = styled(Paper)`
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  margin-left: 20px;
`;

export const MenuList = styled(MuiMenuList)`
  padding: 4px 0;
`;

export const MenuItem = styled(MuiMenuItem)`
  padding: 12px 24px;
`;
