import styled from 'styled-components';
import {
  Typography,
  TypographyProps as MuiTypographyProps,
} from '@material-ui/core';
import { margin, MarginProps, padding, PaddingProps } from 'helpers';

export type TypographyProps = MuiTypographyProps & MarginProps & PaddingProps;

const BODY = styled(Typography)`
  ${margin};
  ${padding};
`;

export const BODY1 = styled(BODY).attrs<TypographyProps>({
  variant: 'body1',
})<TypographyProps>``;

export const BODY2 = styled(BODY).attrs<TypographyProps>({
  variant: 'body2',
})<TypographyProps>``;

/**
 * is a custom variant for small text
 * */
export const BODY3 = styled(BODY1)<TypographyProps>`
  font-size: ${({ theme }) => theme.typography.body3.mobile.fontSize};
  line-height: ${({ theme }) => theme.typography.body3.mobile.lineHeight};

  @media ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.typography.body3.fontSize};
    line-height: ${({ theme }) => theme.typography.body3.lineHeight};
  }
`;

export const SUBTITLE1 = styled(Typography).attrs<TypographyProps>({
  variant: 'subtitle1',
})<TypographyProps>`
  text-transform: uppercase;
  ${margin};
  ${padding};
`;
export const SUBTITLE2 = styled(Typography).attrs<TypographyProps>({
  variant: 'subtitle2',
})<TypographyProps>`
  ${margin};
  ${padding};
`;

export const CAPTION = styled(Typography).attrs<TypographyProps>({
  variant: 'caption',
})<TypographyProps>`
  ${margin};
  ${padding};
`;

export const OVERLINE = styled(Typography).attrs<TypographyProps>({
  variant: 'overline',
})<TypographyProps>`
  ${margin};
  ${padding};
`;
