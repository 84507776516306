import styled from 'styled-components';
import { BODY1, Container } from '../Elements';

export const ActionSectionContainer = styled(Container)`
  margin: 110px 0;

  ${BODY1} {
    margin: 25px 0 36px;
  }

  @media ${({ theme }) => theme.media.tablet} {
    margin: 200px 0;

    ${BODY1} {
      margin: 40px 0;
    }
  }
`;
