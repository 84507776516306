import { css } from 'styled-components';

const getPaddingTop = (padding: number) => css`
  padding-top: ${padding}px;
`;

const getPaddingBottom = (padding: number) => css`
  padding-bottom: ${padding}px;
`;

const getVerticalPadding = (padding: number) => css`
  ${getPaddingTop(padding)}
  ${getPaddingBottom(padding)}
`;

const getPaddingLeft = (padding: number) => css`
  padding-left: ${padding}px;
`;

const getPaddingRight = (padding: number) => css`
  padding-right: ${padding}px;
`;

const getHorizontalPadding = (padding: number) => css`
  ${getPaddingLeft(padding)}
  ${getPaddingRight(padding)}
`;

export type PaddingProps = {
  $verticalPadding?: number;
  $paddingTop?: number;
  $paddingBottom?: number;
  $horizontalPadding?: number;
  $paddingLeft?: number;
  $paddingRight?: number;
};

export const padding = css<PaddingProps>`
  ${({ $verticalPadding }) =>
    $verticalPadding !== undefined
      ? getVerticalPadding($verticalPadding)
      : undefined};

  ${({ $paddingTop }) =>
    $paddingTop !== undefined ? getPaddingTop($paddingTop) : undefined};

  ${({ $paddingBottom }) =>
    $paddingBottom !== undefined
      ? getPaddingBottom($paddingBottom)
      : undefined};

  ${({ $horizontalPadding }) =>
    $horizontalPadding !== undefined
      ? getHorizontalPadding($horizontalPadding)
      : undefined};

  ${({ $paddingLeft }) =>
    $paddingLeft !== undefined ? getPaddingLeft($paddingLeft) : undefined};

  ${({ $paddingRight }) =>
    $paddingRight !== undefined ? getPaddingRight($paddingRight) : undefined};
`;
