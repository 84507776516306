import { TextField, TextFieldProps as MuiInputProps } from '@material-ui/core';
import styled from 'styled-components';

export type InputProps = MuiInputProps;

/**
 * Input allow users to enter text into a UI. They typically appear in forms and dialogs.
 * The current Input is a mui-based, complete form control including a label, input and help text.
 * */

export const Input = styled(TextField).attrs(
  ({ variant = 'outlined', color = 'primary' }) => ({
    variant,
    color,
  }),
)`
  .MuiOutlinedInput-root {
    background: ${({ theme }) => theme.palette.common.white};
    border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  }

  .MuiOutlinedInput-root.Mui-disabled {
    background: ${({ theme }) => theme.palette.common.grey[200]};
    color: ${({ theme }) => theme.palette.common.grey[600]};
  }

  .MuiOutlinedInput-input {
    ::placeholder {
      color: ${({ theme }) => theme.palette.common.grey[600]};
      opacity: 1;
    }
  }

  .MuiOutlinedInput-input:not(textarea) {
    padding: 0 18px;
    height: 60px;
    display: flex;
    align-items: center;
  }

  .MuiOutlinedInput-multiline {
    padding: 18px;
  }

  .MuiOutlinedInput-root fieldset {
    border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  }

  .MuiOutlinedInput-root.Mui-focused fieldset,
  .MuiOutlinedInput-root.Mui-error fieldset {
    border-width: 2px;
  }

  .MuiOutlinedInput-root:hover:not(.Mui-focused):not(.Mui-error) fieldset {
    border-color: ${({ theme }) => theme.palette.common.grey[400]};
  }
`;
