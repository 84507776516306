import styled from 'styled-components';
import { ColourName, Intensity } from '@material-ui/core/styles/createPalette';
import { LinearProgressProps } from './index';

const createLinearGradient = (colours: string[]): string => {
  return `linear-gradient(90deg, ${colours.join(',')})`;
};

type ProgressProps = {
  $value: LinearProgressProps['value'];
  $colour: ColourName;
  $intensity: Intensity | Intensity[];
  $avoidZero: boolean;
  $indicator?: boolean;
};

export const ProgressWrapper = styled.div<{ $indicator: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: ${({ $indicator }) => ($indicator ? '16px' : '8px')};
`;

export const ProgressTrack = styled.div<{
  $rounded: LinearProgressProps['rounded'];
}>`
  height: 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.common.grey[100]};
  position: relative;
  border-radius: ${({ $rounded }) => ($rounded ? '20px' : 0)};
  overflow: hidden;
`;

export const ProgressBar = styled.div<ProgressProps>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme, $colour, $intensity }) => {
    if ($colour === 'black' || $colour === 'white') {
      return theme.palette.common[$colour];
    }

    if (Array.isArray($intensity)) {
      return 'inherit';
    } else {
      return theme.palette.common[$colour][$intensity];
    }
  }};
  background-image: ${({ $intensity, $colour, theme }) =>
    Array.isArray($intensity)
      ? createLinearGradient(
          $intensity.map(intn => theme.palette.common[$colour][intn]),
        )
      : 'none'};
  width: ${({ $value, $avoidZero }) =>
    $avoidZero ? `max(${$value}%, 15px)` : `${$value}%`};
  height: 100%;
  border-radius: ${({ $indicator }) => ($indicator ? '0 20px 20px 0' : 0)};
`;

export const Indicator = styled.div<ProgressProps>`
  position: absolute;
  height: 8px;
  width: 8px;
  background-color: ${({ theme, $colour, $intensity }) => {
    if ($colour === 'black' || $colour === 'white') {
      return theme.palette.common[$colour];
    }
    if (Array.isArray($intensity)) {
      return theme.palette.common[$colour][$intensity[$intensity.length - 1]];
    } else {
      return theme.palette.common[$colour][$intensity];
    }
  }};
  border: 4px solid ${({ theme }) => theme.palette.common.white};
  left: ${({ $avoidZero, $value }) =>
    `max(calc(${$value}% - 16px), ${$avoidZero ? '7px' : '0px'})`};
  box-sizing: content-box;
  border-radius: 50%;
`;
