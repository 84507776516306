import styled from 'styled-components';
import {
  Container,
  GridItem,
  Button,
  CAPTION,
  H4,
  SUBTITLE2,
  Input,
} from 'components/Elements';

export const LinksGridItem = styled(GridItem)<{ $error?: boolean }>`
  margin-bottom: 10px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const TintedContainer = styled(Container)`
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const CaptionGridItem = styled(GridItem)`
  margin: 48px 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 64px 0 24px;
  }
`;

export const SectionTitle = styled(H4)`
  text-transform: uppercase;
`;

export const Title = styled(H4)``;

export const FooterContainer = styled(Container)`
  margin: 40px 0;

  ${CAPTION}, ${SUBTITLE2}, ${SectionTitle}, ${Title}, .MuiLink-root, ${Button} {
    color: ${({ theme }) => theme.palette.text.light};
  }

  ${SectionTitle} {
    margin: 48px 0 16px;
  }

  ${Title} {
    margin-bottom: 32px;
  }

  .MuiLink-root {
    line-height: 16px;
    margin-bottom: 10px;
  }

  ${Input} {
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 60px auto;

    ${Title} {
      margin-bottom: 40px;
    }

    ${SectionTitle} {
      margin: 0 0 32px;
    }

    ${Button} {
      margin-top: 6px;
    }
  }

  .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;
