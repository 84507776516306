import styled from 'styled-components';
import { Drawer } from '@material-ui/core';

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorTop {
    top: 60px;
    z-index: ${({ theme }) => theme.zIndex.appBar - 1};
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.dark};
    text-align: center;
    padding: 30px 0;
  }
`;
