import { css } from 'styled-components';

const getMarginTop = (margin: number) => css`
  margin-top: ${margin}px;
`;

const getMarginBottom = (margin: number) => css`
  margin-bottom: ${margin}px;
`;

const getVerticalMargin = (margin: number) => css`
  ${getMarginTop(margin)}
  ${getMarginBottom(margin)}
`;

const getMarginLeft = (margin: number) => css`
  margin-left: ${margin}px;
`;

const getMarginRight = (margin: number) => css`
  margin-right: ${margin}px;
`;

const getHorizontalMargin = (margin: number) => css`
  ${getMarginLeft(margin)}
  ${getMarginRight(margin)}
`;

export type MarginProps = {
  $verticalMargin?: number;
  $marginTop?: number;
  $marginBottom?: number;
  $horizontalMargin?: number;
  $marginLeft?: number;
  $marginRight?: number;
};

export const margin = css<MarginProps>`
  & {
    ${({ $verticalMargin }) =>
      $verticalMargin !== undefined
        ? getVerticalMargin($verticalMargin)
        : undefined};

    ${({ $marginTop }) =>
      $marginTop !== undefined ? getMarginTop($marginTop) : undefined};

    ${({ $marginBottom }) =>
      $marginBottom !== undefined ? getMarginBottom($marginBottom) : undefined};

    ${({ $horizontalMargin }) =>
      $horizontalMargin !== undefined
        ? getHorizontalMargin($horizontalMargin)
        : undefined};

    ${({ $marginLeft }) =>
      $marginLeft !== undefined ? getMarginLeft($marginLeft) : undefined};

    ${({ $marginRight }) =>
      $marginRight !== undefined ? getMarginRight($marginRight) : undefined};
  }
`;
