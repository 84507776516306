import React, { ReactNode } from 'react';
import { StartAdornment, EndAdornment, Block } from './styles';

export type ContentBlockProps = {
  /**
   * Content is not wrapped in any div inside ContentBlock.
   * If you need to shift `startAdornment` to the left use `margin-left: auto` on your `content`.
   * If you need to shift `endAdornment` to the right use `margin-right: auto` or `width: 100%` on your content.
   */
  content: ReactNode;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  className?: string;
  alignItems?: 'flex-start' | 'center' | 'flex-end';
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  onClick?: () => void;
};

/**
 * ContentBlock is a reusable layout component. Core elements of it are startAdornment, content and endAdornment.
 * All of the element can be full filled with any ReactNode.<br />
 * Most common use cases are: <br />
 * - icon + text/content<br />
 * - checkbox + label<br />
 **/
export const ContentBlock: React.FC<ContentBlockProps> = ({
  content,
  startAdornment,
  endAdornment,
  className,
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  onClick,
}) => {
  return (
    <Block
      className={className}
      $alignItems={alignItems}
      $justifyContent={justifyContent}
      onClick={onClick}
    >
      {startAdornment && <StartAdornment>{startAdornment}</StartAdornment>}
      {content}
      {endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
    </Block>
  );
};
