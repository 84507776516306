import React, { ReactElement } from 'react';
import {
  RecommendationCard as Card,
  CardContent,
  StyledChip as Chip,
  CheckCircleIcon,
  BenefitDetailContainer,
  ExpandRowIconButton,
  ChipContainer,
  BenefitCaption,
  StyledButton as Button,
  CrossCircleIcon,
  HeaderContainer,
  BenefitCaptionContainer,
  SmallQuestionMarkIcon,
  GenericsCaptionContainer,
  GenericsCaption,
} from './styles';
import { CAPTION, BODY2, H4, BaseLink as Link } from 'components/Elements';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import ClearOutlined from '@material-ui/icons/ClearOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Collapse, useMediaQuery, useTheme, Popover } from '@material-ui/core';
import { BenefitStateProps } from '../RecommendationTable';

interface BenefitProps {
  name: string;
  icon: ReactElement;
  impact: string;
  caption: string;
}

export type RecommendationCardProps = {
  name: string;
  generics?: string[];
  status: 'best' | 'current' | 'currentUnrecommended';
  type: string;
  benefits?: BenefitProps[];
  benefitStateArray?: BenefitStateProps[];
  setBenefitStateArrayCallback: (
    benefitStateArray: BenefitStateProps[],
  ) => void;
  onLearnMoreClickCallback: () => void;
};

const STATUS_MAPPINGS = {
  best: 'Best available to you',
  current: 'Current pill',
  currentUnrecommended: 'Current pill',
};

const IMPACT_MAPPINGS = {
  IMPROVE: 'May improve',
  WORSEN: 'May worsen',
  'IMPROVE / WORSEN': 'May improve / worsen',
  UNKNOWN: 'Unknown',
  'NOT KNOWN TO AFFECT': 'Not known to affect',
};

const IMPACT_ICON_MAPPINGS = {
  IMPROVE: <CheckOutlined fontSize="small" />,
  WORSEN: <ClearOutlined fontSize="small" />,
  'IMPROVE / WORSEN': <SmallQuestionMarkIcon />,
  UNKNOWN: <SmallQuestionMarkIcon />,
};

export const RecommendationCard: React.FC<RecommendationCardProps> = ({
  name,
  generics,
  status,
  type,
  benefits,
  benefitStateArray,
  setBenefitStateArrayCallback,
  onLearnMoreClickCallback,
}) => {
  const toggleBenefitBlock = (benefit?: BenefitStateProps) => {
    if (benefit && benefits && benefitStateArray) {
      const newBenefit = { ...benefit, isOpen: !benefit.isOpen };
      const index = benefits.findIndex(
        element => element.name === benefit.name,
      );
      const newBenefitStateArray = [...benefitStateArray];
      newBenefitStateArray[index] = newBenefit;
      setBenefitStateArrayCallback(newBenefitStateArray);
    }
  };

  const [
    genericCaptionAnchorEl,
    setGenericCaptionAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setGenericCaptionAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setGenericCaptionAnchorEl(null);
  };
  const open = Boolean(genericCaptionAnchorEl);

  const statusIcon = () => {
    switch (status) {
      case 'best':
        return <CheckCircleIcon />;
      case 'currentUnrecommended':
        return <CrossCircleIcon />;
      case 'current':
      default:
        return undefined;
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Card $status={status} $isMobile={isMobile}>
      <CardContent>
        <HeaderContainer>
          <H4>{name}</H4>
          <GenericsCaptionContainer>
            <GenericsCaption $isMobile={isMobile} $paddingRight={10}>
              {generics?.join(', ') || ''}
            </GenericsCaption>
            {generics ? (
              <div>
                <Link variant="body2" onClick={handleClick}>
                  {generics?.length}
                </Link>
                <Popover
                  open={open}
                  anchorEl={genericCaptionAnchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <CAPTION $paddingRight={10} $paddingLeft={10}>
                    {generics?.join(', ')}
                  </CAPTION>
                </Popover>
              </div>
            ) : null}
          </GenericsCaptionContainer>
          <ChipContainer>
            <Chip
              label={STATUS_MAPPINGS[status]}
              icon={statusIcon()}
              $status={status}
            />
            <Chip label={`${type}`} $status={status} />
          </ChipContainer>
        </HeaderContainer>
        {benefits?.map(benefit => {
          const benefitWithState = benefitStateArray?.find(
            element => element.name === benefit.name,
          );
          return (
            <div key={benefit.name}>
              <BenefitDetailContainer>
                {IMPACT_ICON_MAPPINGS[benefit.impact]}
                <BODY2 $paddingTop={3} $paddingLeft={10}>
                  {IMPACT_MAPPINGS[benefit.impact]}{' '}
                  {isMobile
                    ? benefit.impact === 'UNKNOWN'
                      ? `for ${benefit.name}`
                      : benefit.name
                    : ''}
                </BODY2>
                <ExpandRowIconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => toggleBenefitBlock(benefitWithState)}
                >
                  {benefitWithState?.isOpen ? (
                    <ExpandLessIcon fontSize="small" />
                  ) : (
                    <ExpandMoreOutlinedIcon fontSize="small" />
                  )}
                </ExpandRowIconButton>
              </BenefitDetailContainer>
              <Collapse
                in={benefitWithState?.isOpen}
                unmountOnExit
                collapsedHeight={benefitWithState?.height || 250}
                timeout={100}
              >
                <BenefitCaptionContainer>
                  <BenefitCaption>{benefit.caption}</BenefitCaption>
                </BenefitCaptionContainer>
              </Collapse>
            </div>
          );
        })}
        <Button $status={status} fullWidth onClick={onLearnMoreClickCallback}>
          LEARN MORE
        </Button>
      </CardContent>
    </Card>
  );
};
