import { Button } from 'components/Elements';
import styled from 'styled-components';

export const HeaderLink = styled.div<{
  $color: 'primary' | 'secondary' | 'light';
}>`
  padding: 16px 0;
  margin-right: 0;

  .MuiLink-root {
    text-transform: uppercase;
    font-weight: 500;
    white-space: nowrap;
    color: ${({ theme, $color }) => theme.palette.text[$color]};
  }
`;

export const StyledButton = styled(Button)`
  :not(.MuiButton-textSizeSmall):not(.MuiButton-textSizeLarge) {
    padding: 12px 16px;
  }
`;
