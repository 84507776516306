import React from 'react';
import {
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
} from '@material-ui/core';
import styled from 'styled-components';

const SnackContent = styled.div`
  background-color: ${({ theme }) => theme.palette.common.green[400]};
  color: ${({ theme }) => theme.palette.common.white};
  padding: 18px 20px;
  border-radius: ${({ theme }) => theme.borderRadius.small}px;
`;

export const Snackbar: React.FC<MuiSnackbarProps & { message: string }> = ({
  message,
  ...props
}) => {
  return (
    <MuiSnackbar {...props}>
      <SnackContent>{message}</SnackContent>
    </MuiSnackbar>
  );
};
