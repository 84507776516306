// we follow Material Design system
// https://material.io/design/layout/responsive-layout-grid.html#columns-gutters-and-margins

export const gutters = {
  desktop: 18,
  mobile: 20,
};

// Margin values are equal to default that MUI uses
// 16px for screens under 600px, 24px for bigger
export const margins = {
  desktop: 24,
  mobile: 16,
};

export const screenSizes = {
  xs: 0, // 4cols
  sm: 600, // 8cols
  md: 960, // 8cols
  lg: 1280, // 12cols
  xl: 1920, // 12cols
};

// mobile first approach
export const breakpoints = {
  mobileS: `(min-width:${screenSizes.xs}px)`,
  mobile: `(min-width:${screenSizes.sm}px)`,
  tablet: `(min-width:${screenSizes.md}px)`,
  laptop: `(min-width:${screenSizes.lg}px)`,
  desktop: `(min-width:${screenSizes.xl}px)`,
};
