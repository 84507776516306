import styled from 'styled-components';

export const Block = styled.div<{
  $alignItems: 'center' | 'flex-start' | 'flex-end';
  $justifyContent: 'center' | 'flex-start' | 'flex-end' | 'space-between';
}>`
  display: flex;
  flex-wrap: nowrap;
  align-items: ${({ $alignItems }) => $alignItems};
  justify-content: ${({ $justifyContent }) => $justifyContent};

  .MuiCheckbox-root {
    padding: 0;
  }

  label {
    cursor: pointer;
  }
`;

export const StartAdornment = styled.div`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

export const EndAdornment = styled.div`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;
