import styled, { css } from 'styled-components';
import { Grid as MuiGrid, GridProps } from '@material-ui/core';

export type GridContainerProps = Partial<GridProps> & {
  /**
   * Set the value of the prop to `true` if you want to disable negative margins on `GridContainer`.
   * Those styles are used to neutralize `GridItem` padding for the first and last item.
   * @default false
   */
  $disableNegativeMargins?: boolean;
};

/*
 * Negative margins needed to neutralize `GridItem` padding for the first and last item
 * */
const NegativeMargins = css`
  margin-left: ${({ theme }) => `-${theme.breakpoints.gutters.mobile / 2}px`};
  margin-right: ${({ theme }) => `-${theme.breakpoints.gutters.mobile / 2}px`};
  width: ${({ theme }) => `calc(100% + ${theme.breakpoints.gutters.mobile}px)`};

  @media ${({ theme }) => theme.media.mobile} {
    margin-left: ${({ theme }) =>
      `-${theme.breakpoints.gutters.desktop / 2}px`};
    margin-right: ${({ theme }) =>
      `-${theme.breakpoints.gutters.desktop / 2}px`};
    width: ${({ theme }) =>
      `calc(100% + ${theme.breakpoints.gutters.desktop}px)`};
  }
`;
/**
 * Wrapper component for content.
 * Represents outer building block that has the flex container behavior.
 * You should be wrapping items (`GridItem`) with a container (`GridContainer`).
 */
export const GridContainer = styled(MuiGrid).attrs<GridContainerProps>(
  ({ $disableNegativeMargins = false }) => ({
    container: true,
    item: false,
    spacing: 0,
    $disableNegativeMargins: $disableNegativeMargins,
  }),
)<GridContainerProps>`
  ${({ $disableNegativeMargins }) =>
    !$disableNegativeMargins ? NegativeMargins : null}
`;

export type GridItemProps = Partial<GridProps> & {
  /**
   * Removes horizontal paddings from `GridItem` if needed.
   * @default false
   */
  $disableGutters?: boolean;
  /**
   * Move columns to the right by adding the offset property.
   * This property increases the margin left of the column by the number of specified columns.
   * `$offset` (applied to all screen sizes)
   */
  $offset?: number;
  /**
   * Offsets can also be added based on screen breakpoints.
   * `$offsetSm` (applied to Sm and up)
   * */
  $offsetSm?: number;
  /**
   * ` $offsetMd` (applied to Md and up)
   * */
  $offsetMd?: number;
  /**
   * ` $offsetLg` (applied to Lg and up)
   * */
  $offsetLg?: number;
  /**
   * ` $offsetXl` (applied to Xl and up)
   * */
  $offsetXl?: number;
};

/**
 * Inner building block. Wrapper component for content.
 * Should be nested inside `GridContainer`.
 * Can behave both as item and container.
 */
export const GridItem = styled(MuiGrid).attrs<GridItemProps>(
  ({ container = false }) => ({
    item: true,
    container,
    spacing: container ? 0 : undefined,
  }),
)<GridItemProps>`
  padding: ${({ $disableGutters, theme }) =>
    $disableGutters ? '0' : `0 ${theme.breakpoints.gutters.mobile / 2}px`};

  margin-left: ${({ $offset }) =>
    $offset ? `${($offset / 12) * 100}%` : undefined};

  @media ${({ theme }) => theme.media.mobile} {
    padding: ${({ $disableGutters, theme }) =>
      $disableGutters ? '0' : `0 ${theme.breakpoints.gutters.desktop / 2}px`};

    margin-left: ${({ $offsetSm }) =>
      $offsetSm ? `${($offsetSm / 12) * 100}%` : undefined};
  }

  @media ${({ theme }) => theme.media.tablet} {
    margin-left: ${({ $offsetMd }) =>
      $offsetMd ? `${($offsetMd / 12) * 100}%` : undefined};
  }

  @media ${({ theme }) => theme.media.laptop} {
    margin-left: ${({ $offsetLg }) =>
      $offsetLg ? `${($offsetLg / 12) * 100}%` : undefined};
  }

  @media ${({ theme }) => theme.media.desktop} {
    margin-left: ${({ $offsetXl }) =>
      $offsetXl ? `${($offsetXl / 12) * 100}%` : undefined};
  }
`;
