import React from 'react';
import { Input } from '../Input';
import {
  Paper,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import styled from 'styled-components';

export type SelectProps = MuiSelectProps;

export const SelectPaper = styled(Paper).attrs(({ elevation = 8 }) => ({
  elevation,
}))`
  margin-top: 4px;
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
`;

const BaseSelect: React.FC<SelectProps> = ({
  color = 'primary',
  variant = 'outlined',
  label,
  input,
  children,
  ...props
}) => {
  const defaultInput = <Input select variant={variant} label={label} />;

  return (
    <MuiSelect
      color={color}
      input={input || defaultInput}
      IconComponent={ExpandMoreRoundedIcon}
      MenuProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        PaperProps: {
          component: SelectPaper,
        },
      }}
      {...props}
    >
      {children}
    </MuiSelect>
  );
};

export const Select = styled(BaseSelect)`
  .MuiSelect-icon {
    margin-right: 18px;
  }

  .MuiSelect-select:focus {
    background-color: unset;
  }

  .MuiSelect-select.MuiSelect-select {
    padding-right: calc(18px + 24px + 8px);
  }
`;
