import React from 'react';
import { createMuiTheme, Theme } from '@material-ui/core';
import { colors, shadow } from './palette';
import { screenSizes, breakpoints, gutters, margins } from './breakpoints';
import { fontSizes, fontFamily } from './typography';
import { borderRadius } from './shape';

declare module '@material-ui/core/styles/createMuiTheme' {
  type Media = {
    mobileS: string;
    mobile: string;
    tablet: string;
    laptop: string;
    desktop: string;
  };

  type BorderRadius = {
    medium: number;
    small: number;
    large: number;
  };

  interface Theme {
    media: Media;

    card: {
      shadow: {
        primary: React.CSSProperties['boxShadow'];
        secondary: React.CSSProperties['boxShadow'];
      };
    };

    borderRadius: BorderRadius;
  }

  interface ThemeOptions {
    media?: Media;

    card?: {
      shadow?: {
        primary: React.CSSProperties['boxShadow'];
        secondary: React.CSSProperties['boxShadow'];
      };
    };

    borderRadius?: BorderRadius;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface TypeText {
    light: string;
    dark: string;
  }

  interface TypeBackground {
    dark: string;
    light: string;
    main: string;
  }

  interface Palette {
    button: {
      primary: React.CSSProperties['color'];
      secondary: React.CSSProperties['color'];
    };

    border: {
      main: React.CSSProperties['color'];
      dark: React.CSSProperties['color'];
      light: React.CSSProperties['color'];
    };

    stepper: {
      default: React.CSSProperties['color'];
      active: React.CSSProperties['color'];
    };

    icon: {
      main: React.CSSProperties['color'];
      dark: React.CSSProperties['color'];
      light: React.CSSProperties['color'];
    };
  }

  interface PaletteOptions {
    button?: {
      primary?: React.CSSProperties['color'];
      secondary?: React.CSSProperties['color'];
    };

    border?: {
      main?: React.CSSProperties['color'];
      dark?: React.CSSProperties['color'];
      light?: React.CSSProperties['color'];
    };

    stepper?: {
      default: React.CSSProperties['color'];
      active: React.CSSProperties['color'];
    };

    icon?: {
      main: React.CSSProperties['color'];
      dark?: React.CSSProperties['color'];
      light?: React.CSSProperties['color'];
    };
  }

  export type Intensity =
    | 50
    | 100
    | 200
    | 300
    | 400
    | 500
    | 600
    | 700
    | 800
    | 900;

  export type ColourName =
    | 'white'
    | 'black'
    | 'purple'
    | 'green'
    | 'red'
    | 'yellow'
    | 'grey';

  interface CommonColors {
    white: string;
    black: string;
    purple: Record<Intensity, string>;
    green: Record<Intensity, string>;
    red: Record<Intensity, string>;
    yellow: Record<Intensity, string>;
    grey: Record<Intensity, string>;
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface FontProps extends TypographyStyleOptions {
    mobile: TypographyStyleOptions;
  }

  export interface Typography {
    body3: FontProps;
  }

  export interface TypographyOptions {
    body3?: FontProps;
  }
}

declare module '@material-ui/core/styles/createBreakpoints' {
  export interface Breakpoints {
    gutters: {
      desktop: number;
      mobile: number;
    };

    margins: {
      desktop: number;
      mobile: number;
    };
  }
}

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}

const theme: Theme = createMuiTheme({
  typography: {
    fontFamily,
    htmlFontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 600,
    ...fontSizes,
  },
  card: {
    shadow: shadow,
  },
  media: breakpoints,
  breakpoints: {
    gutters,
    margins,
    values: screenSizes,
  },
  shape: {
    borderRadius: borderRadius.small,
  },
  borderRadius,
  palette: {
    text: {
      primary: colors.black,
      secondary: colors.grey[600],
      light: colors.white,
      dark: colors.purple[900],
    },
    primary: {
      main: colors.purple[900],
      light: colors.purple[100],
      dark: colors.black,
    },
    secondary: {
      main: colors.red[600],
      light: colors.red[100],
      dark: colors.red[900],
    },
    background: {
      default: colors.white,
      main: colors.white,
      dark: colors.purple[900],
      light: colors.grey[50],
    },
    button: {
      primary: colors.red[600],
      secondary: colors.purple[900],
    },
    border: {
      main: colors.grey[400],
      dark: colors.purple[900],
      light: colors.purple[100],
    },
    stepper: {
      default: colors.grey[300],
      active: colors.purple[900],
    },
    icon: {
      main: colors.purple[900],
      dark: colors.black,
      light: colors.white,
    },
    error: {
      main: colors.red[900],
    },
    success: {
      main: colors.green[400],
    },
    divider: colors.grey[200],
    common: {
      ...colors,
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontWeight: 400,
        },
      },
    },
    MuiCardContent: {
      root: {
        padding: '48px',
        '&:last-child': {
          paddingBottom: '48px',
        },
        [breakpoints.tablet]: {
          padding: '28px',
          '&:last-child': {
            paddingBottom: '28px',
          },
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(119, 114, 124, 0.3)',
        zIndex: 100,
      },
    },
    MuiDialog: {
      paper: {
        zIndex: 101,
        margin: '20px',
        padding: '24px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        marginBottom: '16px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        marginBottom: '32px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
      },
    },
    MuiDialogContentText: {
      root: {
        marginBottom: '16px',
      },
    },
    MuiChip: {
      root: {
        ...fontSizes.subtitle2,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '36px',
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '2rem',
      },
    },
    MuiAccordion: {
      root: {
        padding: '0 24px',
        borderTop: `1px solid ${colors.grey[200]}`,
        '&:first-child': {
          borderTop: 'none',
        },
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: 'auto',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: '24px 0',
      },
      content: {
        margin: 0,
        '&$expanded': {
          margin: 0,
        },
      },
      expandIcon: {
        padding: '0 12px',
        position: 'absolute',
        right: 0,
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '24px 0',
      },
    },
    MuiAccordionActions: {
      root: {
        padding: '24px 0',
      },
    },
  },
});

export default theme;
