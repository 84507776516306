import React, { ReactNode } from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core';

type LinkProps = MuiLinkProps & {
  InternalLinkComponent?: ReactNode;
};

export const BaseLink: React.FC<LinkProps> = ({
  children,
  // MuiLinkProps
  underline = 'always',
  color = 'textSecondary',
  href,
  InternalLinkComponent,
  ...props
}) => {
  const internal = !!href && /^\/(?!\/)/.test(href);
  const MuiLinkComponent = internal || !href ? 'div' : 'a';

  return (
    <MuiLink
      component={MuiLinkComponent}
      underline={underline}
      href={href}
      color={color}
      variant="body2"
      style={{ cursor: 'pointer' }}
      rel={props.target === '_blank' ? 'noopener' : props.target}
      {...props}
    >
      {internal ? InternalLinkComponent : children}
    </MuiLink>
  );
};
