import styled from 'styled-components';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core';

export type ButtonProps = MuiButtonProps;

const BaseButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '24px',
    },
    contained: {
      boxShadow: theme.shadows[0],
      '&:active': {
        boxShadow: theme.shadows[2],
      },
      '&:not(.MuiButton-containedSizeSmall):not(.MuiButton-containedSizeLarge)': {
        padding: '12px 32px',
      },
    },
    containedPrimary: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    containedSecondary: {
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    outlined: {
      '&:not(.MuiButton-outlinedSizeSmall):not(.MuiButton-outlinedSizeLarge)': {
        padding: '11px 31px',
      },
    },
    outlinedPrimary: {
      borderColor: theme.palette.primary.main,
    },
    outlinedSecondary: {
      borderColor: theme.palette.secondary.main,
    },
    text: {
      '&:not(.MuiButton-textSizeSmall):not(.MuiButton-textSizeLarge)': {
        padding: '12px 32px',
      },
    },
  }),
)(MuiButton);

export const Button = styled(BaseButton).attrs(
  ({ color = 'primary', variant = 'contained' }) => ({
    variant: variant,
    color: color,
  }),
)<{
  color?: string;
}>`
  .MuiLink-root {
    color: inherit;
  }
`;
